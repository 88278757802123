import React, { useState } from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div class="container-terms">
      <p>제1조 (목적)</p>

      <p>본 약관은 한국기업데이터(주)(이하 ‘KED’라 합니다)가 운영하는 myKARROT(이하 ‘서비스’라 합니다)의 이용과 관련하여 KED와 이용자 간의 권리, 의무, 책임사항 및 가입과 이용에 관한 제반 사항, 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</p>

      <p>제2조 (정의)</p>

      <p>이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다. 1. ‘서비스’란 개인 및 기업이 본인정보를 상거래, 여신심사 및 신용평가 등의 목적으로 다운로드 받고 제 3자에게 전송할 수 있도록 ‘KED’가 제공하는 제반 대행 서비스를 말합니다. 2. ‘이용자’란 본 약관에 따라 ‘KED’가 제공하는 ‘서비스’를 이용하는 개인 및 기업 고객을 말합니다. 3. "아이디(ID)"란 ‘이용자’의 식별과 ‘서비스’ 이용을 위하여 ‘이용자’가 정하고 ‘KED’가 승인하는 문자와 숫자의 조합을 의미합니다. 4. ‘비밀번호’란 ‘이용자’가 부여 받은 ‘아이디’와 일치되는 ‘이용자’임을 확인하고 비밀보호를 위해 ‘이용자’ 자신이 정한 문자 또는 숫자의 조합을 의미합니다. 5. ‘이용계약’이란 서비스 이용과 관련하여 ‘KED’와 ‘이용자’간에 체결하는 모든 계약을 말합니다. 6. ‘공지사항’이란 ‘KED’가 ‘이용자’에게 전산망을 통하여 고지하는 내용을 말합니다.</p>

      <p>제3조 (약관의 효력 및 변경)</p>

      <p>① 본 약관에 첨부되었거나 첨부될 내용 및 ‘공지사항’을 통해 공지된 내용은 본 약관의 일부로서의 효력을 가집니다.② 관련 법률의 변경이나 회사 정책상의 사유 등 회사가 필요하다고 인정하는 경우 본 약관은 변경될 수 있습니다.③ ‘KED’는 약관의 내용에 변경이 있을 경우에는 그 내용을 ‘공지사항’에 게시하며, 공지 후 2주간 ‘이용자’의 이의가 없으면 그 변경에 동의한 것으로 봅니다.</p>

      <p>제4조 (이용계약 체결)</p>

      <p>① 이용계약은 ‘이용자’가 되고자 하는 고객이 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 ‘KED’가 이러한 신청에 대하여 승낙함으로써 체결됩니다. ② ‘KED’는 가입신청자의 신청에 대하여 ‘서비스’ 이용을 승낙함을 원칙으로 합니다. 다만, ‘KED’는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다. 1. 실명이 아니거나 타인의 명의를 이용한 경우 2. 허위의 정보를 기재하거나, ‘KED’가 제시하는 내용을 기재하지 않은 경우 3. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우 4. 법령위반, 영리추구 등 부정한 목적으로 서비스를 이용하고자 한 경우 ③ ‘KED’는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다. ④ 이용계약의 성립 시기는 ‘KED’가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>


      <p>제5조 (개인정보보호 의무)</p>

      <p>‘KED’는 ‘신용정보의 이용 및 보호에 관한 법률’ 등 관계 법령이 정하는 바에 따라 ‘이용자’의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 ‘KED’의 개인정보처리방침과 개인정보취급방침이 적용됩니다.</p>


      <p>제6조(‘이용자’의 ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무)</p>

      <p>① ‘이용자’의 ‘아이디’와 ‘비밀번호’에 관한 관리책임은 ‘이용자’에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다. ② ‘KED’는 ‘이용자’의 ‘아이디’가 개인정보 유출 우려가 있거나, ‘KED’ 및 ‘KED’의 운영자로 오인할 우려 등이 있는 경우, 해당 ‘이용자’의 이용을 제한할 수 있습니다.</p>


      <p>제7조 (‘KED’의 의무)</p>

      <p>① ‘KED’는 관련 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 ‘서비스’를 제공하기 위하여 최선을 다하여 노력합니다. ② ‘KED’는 계속적이고 안정적인 서비스를 위하여 설비에 장애가 생길 경우 지체없이 수리 또는 복구하여야 합니다, 단, ‘KED’의 귀책사유없이 발생하는 서비스 이용중지에 대해서는 책임을 지지 않습니다 ③ ‘KED’는 ‘이용자’가 안전하게 ‘서비스’를 이용할 수 있도록 개인신용정보 보호를 위한 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.</p>


      <p>제8조 (‘이용자’의 의무)</p>

      <p>① ‘이용자’는 다음 행위를 하여서는 안 됩니다. 1. 신청 또는 변경시 허위내용의 등록 2. 타인의 정보 도용 3. ‘KED’가 게시한 정보의 변경 4. ‘KED’가 정한 정보 이외의 정보 등의 송신 또는 게시 5. 전송파일을 위조나 변조하여 유관기관에 신고한 내용과 다른 내용의 자료를 전송하는 행위 6. 회사의 동의 없이 영리를 목적으로 ‘서비스’를 사용하는 행위 7. 기타 불법적이거나 부당한 행위 ② ‘이용자’는 관련 법령, 이 약관의 규정, 이용안내 및 ‘서비스’와 관련하여 공지한 주의사항, ‘KED’가 통지하는 사항 등을 준수하여야 하며, 기타 ‘KED’의 업무에 방해되는 행위를 하여서는 안 됩니다. ③ ‘이용자’는 ‘KED’의 사전승낙 없이 본 서비스를 이용하여 영업활동을 할 수 없으며 영업활동으로 인하여 ‘KED’가 손해를 입을 경우 ‘KED’는 ‘이용자’에게 손해배상을 청구할 수 있습니다. ④ ‘이용자’는 회사의 동의없이 서비스의 이용권한, 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>


      <p>제9조 (‘서비스’의 변경 및 중단)</p>

      <p>① ‘KED’는 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 ‘서비스’를 변경할 수 있습니다. ② ‘KED’는 정보통신설비의 보수, 교체, 고장 등의 경우에 서비스 제공을 일시적으로 중단할 수 있으며, 이 경우 회사는 사전에 ‘이용자’에게 공지하여야 합니다.</p>


      <p>제10조 (전송정보의 제공 동의)</p>

      <p>‘KED’는 ‘이용자’가 직접 혹은 세무회계사무소를 통해 ‘KED’에 본인 정보 다운로드 및 전송 대행을 의뢰하여 자료를 다운로드 받거나 제 3자에게 전송하는 경우 ‘이용자’가 직접 다운로드 받거나 제 3자에게 본인 정보를 전송한 것과 동일한 효력이 있는 것으로 간주하며, ‘이용자’가 ‘서비스’를 통해 다운로드 받거나 제 3자에게 전송한 본인 정보는 서비스의 제공, 유지 및 관리를 위하여 ‘KED’에 제공되고 ‘이용자’는 이에 동의한 것으로 판단합니다.</p>


      <p>제11조 (이용제한 등)</p>

      <p>‘KED’는 ‘이용자’가 이 약관의 의무를 위반하거나 ‘서비스’의 정상적인 운영을 방해한 경우 ‘서비스’ 이용을 제한 및 정지시킬 수 있습니다.</p>


      <p>제12조 (면책)</p>

      <p>① ‘KED’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘서비스’를 제공할 수 없는 경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다. ② ‘KED’는 ‘이용자’의 귀책사유로 인한 ‘서비스’ 이용의 장애에 대하여는 책임을 지지 않습니다. ③ ‘KED’는 설비의 보수, 교체, 정기점검, 공사로 인해 불가피하게 발생하는 손해에 대해서는 책임을 지지 않습니다. ④ ‘KED’는 서비스 이용자가 전송한 자료의 진위 여부 등 내용에 관해서는 책임을 지지 않습니다.</p>


      <p>제13조 (법령준용 및 관할)</p>

      <p>① ‘KED’와 ‘이용자’ 간 서비스 이용과 관련하여 본 약관에 정해지지 아니한 사항에 대해서는 관계법령을 준용합니다. ② ‘KED’와 ‘이용자’간 발생한 분쟁에 관한 소송은 ‘KED’의 본점 소재지를 관할하는 법원으로 합니다.</p>


      <p>(부칙)</p>

      <p>이 약관은 공시한 날로부터 적용됩니다.</p>
    </div>
  );
};

export default Terms;
