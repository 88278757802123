import crypto from "crypto-browserify";

export const encryptionForAES256 = (plain = "", pwd = "") => {
  // console.log("----------AES ENCRYPT----------");
  const cipher = crypto.createCipher("aes256", pwd);
  cipher.setAutoPadding(true);
  let encrypted =
    cipher.update(plain, "binary", "base64") + cipher.final("base64");

  // console.log(encrypted);
  return encrypted;
};

export const decryptionForAES256 = (ciphered, pwd = "") => {
  // console.log("----------AES DECRYPT----------");
  //디사이퍼 생성 - 복호화
  try {
    let decipher = crypto.createDecipher("aes256", pwd);
    let decrypted =
      decipher.update(ciphered, "base64", "utf8") + decipher.final("utf8");

    // console.log(decrypted);
    return decrypted;
  } catch (error) {
    console.log(error);
    return error;
  }
};
