import React, { useState } from "react";
import "./Policy.css";

const Policy = () => {
  return (
    <div class="container-policy">
      <p>
        한국기업데이터(주)는(이하 'KED'라 함) 「신용정보의 이용 및 보호에 관한
        법률」, 「정보통신망이용촉진및정보보호등에관한법률」, 「개인정보보호법」
        등 관련 법령에 따라 개인정보주체의 정보보호를 위하여 본
        개인정보처리방침(이하 ‘방침’)을 정하여 실천하고 있습니다.
      </p>

      <p>
        <b>
          제1조 개인정보의 수집·이용·처리 목적, 수집하는 개인정보의 항목 및
          수집방법
        </b>
      </p>

      <p>
        ‘KED’는 금융위원회의 허가를 받은 신용정보회사로서 허가받은 본 업무, 겸영
        및 부수업무를 수행하기 위해 「신용정보의 이용 및 보호에 관한 법률」 및
        같은 법 시행령에 의거 신용정보주체 및 의뢰인의 개인(신용)정보를 수집하여
        처리합니다.
      </p>

      <p>
        또한 ‘KED’의 홈페이지는 상업적 목적이 아닌 홍보 목적의 사이트로서 고객의
        개인정보를 필요로 하지 않습니다. 그러나 채용 절차에서 입사지원 및
        채용확정시, 1:1친절상담 또는 기능개선의견 등 민원접수의 일부 화면에서
        최소한의 범위 내에서 다음과 같이 개인정보를 수집하게 되는 경우가
        있습니다.
      </p>

      <table class="table-policy col-4">
        <tr class="alignCenter">
          <td>수집·이용 목적</td>
          <td>수집하는 개인정보</td>
          <td>수집방법</td>
          <td>수집 근거</td>
        </tr>
        <tr>
          <td>민원처리, 질의·응답</td>
          <td>성명, 전화번호, 이메일</td>
          <td>홈페이지, 서면양식, 전화, 팩스</td>
          <td>
            고객동의, 신용정보의 이용 및 보호에 관한 법률 시행령 제37조의2 제2항
            등
          </td>
        </tr>
        <tr>
          <td>입사지원</td>
          <td>성명, 학력, 경력, 이메일, 전화번호 등</td>
          <td rowspan="2">홈페이지, 서면양식, 이메일 등</td>
          <td>입사 지원자의 동의</td>
        </tr>
        <tr>
          <td>채용확정</td>
          <td>성명, 주민등록번호, 학력, 경력, 이메일, 전화번호 등</td>
          <td>
            채용 확정자의 동의, 근로기준법 제48조, 같은 법 시행령 제27조 제1항,
            고용보험 및 산업재해보상보험의 보험료 징수 등에 관한 법률 제16조의10
            제1항, 제40조, 같은 법 시행령 제19조의5, 제54조의2 등
          </td>
        </tr>
      </table>

      <p>
        <b>제2조 개인정보의 처리, 보유 및 이용 기간</b>
      </p>

      <p>
        ‘KED’는 원칙적으로 개인(신용)정보의 이용목적이 달성되면 지체 없이
        파기합니다. 다만, 다음의 정보에 대해서는 관련 법률에 의거 보존 이유를
        위해 명시한 기간 동안 보존합니다. 이 경우 ‘KED’는 보관하는 정보를 그
        보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
      </p>

      <table class="table-policy col-3">
        <tr class="alignCenter">
          <td>구분</td>
          <td>보존기간</td>
          <td>근거</td>
        </tr>
        <tr>
          <td>신용정보업을 영위하기 위한 개인신용정보</td>
          <td>정보별 법령에 규정된 기간</td>
          <td>신용정보의 이용 및 보호에 관한 법률</td>
        </tr>
        <tr>
          <td>이용자의 문의 내용 및 불만·분쟁 처리에 관한 기록</td>
          <td rowspan="2">3년</td>
          <td>신용정보의 이용 및 보호에 관한 법률</td>
        </tr>
        <tr>
          <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
          <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
        </tr>
        <tr>
          <td>채용확정자에 대한 인사 기록</td>
          <td>관련 법령 및 ‘KED’인사내규에 규정된 기간</td>
          <td>근로기준법 등 관련 법령 등</td>
        </tr>
      </table>

      <p>
        <b>제3조 개인정보의 파기절차 및 방법</b>
      </p>

      <p>
        ‘KED’는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인
        정보를 지체없이 파기합니다. 다만 「신용정보의 이용 및 보호에 관한 법률」
        및 기타 법령에 따라 보존하여야하는 경우에는 그러하지 않습니다. ‘KED'의
        파기절차 및 방법은 다음과 같습니다.
      </p>

      <p>
        1. 파기절차
        <br />
        수집한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
        서류함) 내부 규정 및 제2조에 따라 일정 기간 저장된 후 파기되어집니다.
        별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는
        이외의 다른 목적으로 이용되지 않습니다.
      </p>

      <p>
        2. 파기방법
        <br />
        - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
        방법을 사용하여 삭제합니다.
        <br />- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
        파기합니다.
      </p>

      <p>
        <b>제4조 개인정보의 제3자 제공에 관한 사항</b>
      </p>
      <p>
        ‘KED’는 수집․보유하고 있는 개인(신용)정보를 관련 법령에 따라 제3자에게
        제공합니다.
        <br />
        1. 「신용정보의 이용 및 보호에 관한 법률」에 따라 다음의 기관에
        제공합니다.
        <br />
        - 국가기관, 지방자치단체, 공공단체 및 연구기관
        <br />
        - 신용정보제공·이용자
        <br />
        - 신용정보회사
        <br />
        - 신용정보집중기관
        <br />
        2. 「개인정보 보호법」에 따라 다음의 경우에 제공합니다.
        <br />
        - 정보주체의 동의를 받은 경우
        <br />
        - 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한
        경우
        <br />
        - 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
        주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히
        <br />
        정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고
        인정되는 경우
        <br />
        - 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
        알아볼 수 없는 형태로 개인정보를 제공하는 경우
        <br />
        - 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지
        아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우
        <br />
        로서 보호위원회의 심의·의결을 거친 경우
        <br />
        - 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에
        제공하기 위하여 필요한 경우
        <br />
        - 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
        <br />
        - 법원의 재판업무 수행을 위하여 필요한 경우
        <br />
        - 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우
        <br />
      </p>

      <p>
        <b>제5조 개인정보처리의 위탁에 관한 사항</b>
      </p>

      <p>‘KED’는 개인(신용)정보의 처리를 위탁하고 있지 않습니다.</p>

      <p>
        <b>제6조 신용정보주체의 권리·의무 및 그 행사방법에 관한 사항</b>
      </p>

      <p>
        개인인 신용정보주체는 「신용정보의 이용 및 보호에 관한 법률」에 따라
        다음과 같은 권리를 행사할 수 있습니다.
        <br />
        1. 본인신용정보 열람청구권
        <br />
        본인의 신분을 나타내는 증표를 제시하고 ‘KED’가 보유하고 있는 본인에 관한
        신용정보에 대한 열람을 청구할 수 있습니다.
        <br />
        * 본인정보는 4개월에 1회 무료로 열람이 가능합니다(www.kreport.co.kr).
        <br />
        2. 본인신용정보 정정․삭제청구권
        <br />
        본인정보가 사실과 다른 경우에는 ‘KED’에게 정정 또는 삭제를 청구할 수
        있습니다. ‘KED’는 이러한 청구에 정당한 사유가 있다고 인정하면 즉시
        문제가 된 신용정보에 대하여 정정청구 중 또는 사실조회 중임을 기입하고,
        지체 없이 해당 신용정보의 제공·이용을 중단한 후 사실인지를 조사하여
        사실과 다르거나 확인할 수 없는 신용정보는 삭제·정정 하며, 최근 6개월
        이내에 제공받은 자와 해당 신용정보주체가 요구하는 자에게 해당
        신용정보에서 삭제·정정한 내용을 알려야 합니다. 이러한 처리결과를 7일
        이내에 해당 신용정보주체에게 알립니다.
        <br />
        이와 관련된 안내 및 접수처는 제10조의 고충처리부서와 같습니다.
        <br />
        3. 신용정보 이용 및 제공사실 통보 요구권
        <br />
        본인에 관한 신용정보를 제공한 내역을 통보하도록 요구할 수 있습니다.
        <br />
        4. 신용정보 이용 및 제공사실 조회권
        <br />
        본인에 관한 신용정보를 이용하거나 제공한 내역을 조회할 수
        있습니다.(www.kreport.co.kr)
      </p>

      <p>
        <b>제7조 개인정보주체 및 법정대리인의 권리와 그 행사 방법</b>
      </p>

      <p>
        1. 개인정보주체 및 법정 대리인은 본인 확인절차를 거친 후 자신 혹은 당해
        만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를
        요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부의 이용이
        어려울 수 있습니다.
        <br />
        2. 개인신용정보주체가 개인정보의 오류에 대한 정정을 요청하신 경우에는
        정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
        잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
        제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다. <br />
        3. 개인정보주체 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 제3조에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
        이용할 수 없도록 처리하고 있습니다. <br />
        4. 개인정보주체는 정보주체 이외로부터 수집한 개인정보(개인신용정보는
        제외)의 수집 출처, 처리 목적을 알려주도록 요구할 수 있습니다.
        <br />
        5. 개인정보주체는 본인의 개인정보(개인신용정보는 제외)의 처리정지를
        ‘KED’에 요구할 수 있고 ‘KED’는 정당한 사유가 없는 한 요구를 받은
        날로부터 10일 이내에 개인정보 처리의 일부 또는 전부를 정지하고 해당
        개인정보의 파기 등 정보주체의 요구에 상응하는 조치를 취하고 그 결과를
        알려주어야 합니다. 다만, 다음에 해당하는 경우에는 정보주체의 처리정지
        요구를 거절할 수 있습니다.
        <br />
        - 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한
        경우
        <br />
        - 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의
        이익을 부당하게 침해할 우려가 있는 경우
        <br />- 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지
        못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를
        명확하게 밝히지 아니한 경우
      </p>

      <p>
        <b>제8조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</b>
      </p>

      <p>
        'KED'는 귀하의 정보를 수시로 저장하고 찾아내는 쿠키(cookie)를 운용할 수
        있습니다. 쿠키란 'KED'의 웹사이트 서버가 고객의 브라우저에 보내는 아주
        작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 'KED'는
        다음과 같은 목적을 위해 쿠키를 사용합니다.
        <br />
        1. 쿠키의 사용 목적 : 팝업창을 다음부터 열지 않도록 체크 <br />
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는
        웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될
        때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
        <br />
        2. 쿠키 설정 거부 방법 <br />
        쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을
        선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때 마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. <br />- 설정방법
        예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 >
        개인정보 단, 귀하께서 쿠키 설치를 거부하였을 경우, 팝업창이 매번 열리게
        됩니다.
      </p>

      <p>
        <b>제9조 개인정보의 안전성 확보조치에 관한 사항</b>
      </p>

      <p>
        'KED'는 신용정보회사로 관계법령 등에 따라 다음과 같이 개인(신용)정보의
        안전성확보를 위하여 기술적ㆍ물리적ㆍ관리적 보안대책을 마련, 선진
        신용정보서비스제공을 위해 노력하고 있습니다.
        <br />
        1. 개인(신용)정보 안정성 확보를 위한 내부 관리 계획을 수립하여 운영하고
        있으며 정기적 자체 점검을 실시하고 있습니다. <br />
        2. 개인(신용)정보의 적법한 수집과 이용, 보호에 따른 최종적인 책임을
        이사회와 경영진에게 부여하였습니다.
        <br />
        3. 개인(신용)정보관리·보호업무를 총괄하는 "신용정보관리·보호인" 제도를
        운영하여 전사적으로 보유하는 신용정보 부당이용에 대한 점검의 정확성을
        유지할 수 있도록 하고 있습니다.
        <br />
        4. 개인(신용)정보를 제3자에게 제공하는 경우, 신용정보의 활용과 위규시
        제재 내용 등이 포함된 "보안관리약정"을 체결하고 약정 이행사항을 수시로
        점검하고 있습니다.
        <br />
        5. 정보의 오남용을 방지하기 위해 개인(신용)정보 조회 및 접근권한을
        직급별·업무별로 차등 부여하고 직원의 퇴사, 업무변경 시 지체없이 접근권한
        변경과 말소를 하고 있습니다.
        <br />
        6. 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
        위하여 보안프로그램을 설치 및 주기적인 갱신·점검을 하며, 회사내 모든
        작성문서는 저장 즉시 보안문서화하여 기술적/물리적으로 감시 및 차단하고
        있습니다.
        <br />
        7. 개인(신용)정보가 저장되어 있는 데이터베이스 시스템에 외부 침입을
        방지하기 위한 방화벽 설치, 고유식별번호 및 접근 비밀번호 암호화 관리 등
        정보가 외부로 유출되지 않도록 기술적⋅물리적 보안대책을 마련하여 시행하고
        있습니다.
        <br />
        8. 개인(신용)정보를 직접 처리하는 소속직원 등에 대해 고객 개인정보의
        안전성 확보를 위한 교육을 정기적으로 실시하고 있습니다.
      </p>

      <p>
        <b>
          제10조 개인정보보호 책임자, 개인정보보호 담당부서 및 고충처리 부서에
          관한 사항
        </b>
      </p>

      <p>1. 개인정보보호 책임자 및 담당부서</p>

      <table class="table-policy col-2">
        <tr class="alignCenter">
          <td>개인정보보호책임자</td>
          <td>개인정보보호 담당부서</td>
        </tr>
        <tr class="alignCenter">
          <td>평가부문장 오민근 상무</td>
          <td>평가정책부</td>
        </tr>
      </table>

      <p>2. 고충처리부서</p>

      <table class="table-policy col-3">
        <tr class="alignCenter">
          <td>부서명</td>
          <td>전화번호</td>
          <td>주소</td>
        </tr>
        <tr class="alignCenter">
          <td>고객만족센터</td>
          <td>1811-8883</td>
          <td>서울 영등포구 의사당대로 21(여의도동) 한국기업데이터 빌딩 2층</td>
        </tr>
      </table>

      <p>
        3. 권익침해 구제방법
        <br />
        권익침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
        바랍니다.
        <br />
        - 개인정보분쟁조정위원회 : http://www.kopico.go.kr 1833-6972
        <br />
        - 개인정보침해신고센터 : http://privacy.kisa.or.kr 국번없이 118
        <br />
        - 대검찰청 사이버수사과 : http://www.spo.go.kr 국번없이
        1301,02-3480-2000
        <br />- 경찰청 사이버안전국 : http://cyberbureau.police.go.kr 국번없이
        182
      </p>

      <p>
        <b>제11조 개인정보처리방침의 변경에 관한 사항</b>
      </p>

      <p>
        이 방침은 시행일부터 적용되며, 관련 법령의 변경과 내부운영방침 등의
        변경에 따라 변경될 수 있습니다. 방침의 변경에 따라 내용의 추가, 삭제 및
        수정이 있을 시에는 가능한 변경 시행일의 최소 7일 전부터 ‘KED’ 홈페이지를
        통해 해당 내용을 고지할 것입니다.
        <br />
        1. 개인정보처리방침 변경 공고일자 : 2019년 1월 04일
        <br />
        2. 개인정보처리방침 변경 시행일자 : 2019년 1월 11일
        <br />
      </p>

      <p>
        ※「정보통신망이용촉진및정보보호등에관한법률」제27조의2에서 규정한
        개인정보취급방침을 개인정보처리방침으로 변경하여 개인정보처리 방침과
        개인정보취급방침을 통합
      </p>
    </div>
  );
};

export default Policy;
