import React from "react";
import { BrowserRouter } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import App from "shared/App";

const Root = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default Root;
