import React, { useState } from "react";
import "./NotificationTalk.css";
import axios from "axios";
import { encryptionForAES256, decryptionForAES256 } from "../crypto-bify";
import LoadingIndicator from "react-loading-indicator";

const domainList = [
  { id: 0, domain: "직접입력", value: "" },
  { id: 1, domain: "gmail.com", value: "gmail.com" },
  { id: 2, domain: "naver.com", value: "naver.com" },
  { id: 3, domain: "hanmail.net", value: "hanmail.net" },
  { id: 4, domain: "hotmail.com", value: "hotmail.com" }
];

const NotificationTalk = ({ match }) => {
  const [visible, setVisible] = useState(false);
  const [emailState, setEmailState] = useState(false);

  const req = () => {
    if (emailState) {
      // alert("보안메일 전송 작업 처리중입니다. 잠시만 기다려주세요.");
      return;
    }
    let emailId = document.getElementById("emailId").value;
    let domain = document.getElementById("emailDomain").value;
    let check1 = document.getElementById("check1").checked;
    let check2 = document.getElementById("check2").checked;

    if (check1 && check2) {
      if (emailId && domain) {
        let reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
        if (!reg_email.test(emailId + "@" + domain)) {
          alert("입력한 값이 형식에 맞지 않습니다.");
          return;
        } else {
          let submitBtn = document.querySelector(".submit-btn");
          submitBtn.classList.add("disable");

          // alert("입력한 이메일 : " + id + "@" + domain);

          // let data = new FormData();

          let email = emailId + "@" + domain;

          const KEY = process.env.REACT_APP_KEY;
          const decodedVal = decodeURIComponent(match.params.val);
          // console.log(decodedVal);

          const decryptedVal = decryptionForAES256(decodedVal, KEY);
          // console.log(decryptedVal);

          const splitedVal = decryptedVal.split("&");
          // console.log(splitedVal);

          let from = splitedVal[0].substring(5);
          let id = splitedVal[1].substring(3);
          let type = splitedVal[2].substring(5);
          // let send = splitedVal[3].substring(5) == "true";
          let urlId = splitedVal[4].substring(6)

          // console.log(from, id, type, send);
          setEmailState(true);
          axios
            .get("https://karrot.kr/api/web/sendEmail", {
              params: {
                from: from,
                document: id,
                type: type,
                send: true,
                to: email,
                urlId: urlId,
              }
            })
            .then(response => {
              // console.log(response.data);
              if (response.data.result) {
                alert("보안메일이 전송되었습니다.");
                window.location.href = "https://karrot.kr/";
                setEmailState(false);
                submitBtn.classList.remove("disable");
              } else {
                alert(response.data.message);
                setEmailState(false);
                submitBtn.classList.remove("disable");
              }
            })
            .catch(error => {
              alert("오류: 보안메일 전송에 실패했습니다.");
              console.log(error);
              setEmailState(false);
              submitBtn.classList.remove("disable");
            });
        }
      } else {
        alert("값을 입력해 주세요.");
        return;
      }
    } else {
      alert("내용에 동의하셔야 진행하실 수 있습니다.");
      return;
    }
  };

  const openDrop = () => {
    setVisible(!visible);
  };

  const selectDrop = id => {
    document.getElementById("emailDomain").value = domainList[id].value;
    if (id === 0) {
      document.getElementById("emailDomain").removeAttribute("readonly");
    } else {
      document.getElementById("emailDomain").setAttribute("readonly", "");
    }
    setVisible(false);
  };

  const check = id => {
    document.getElementById(id).checked = !document.getElementById(id).checked;
  };

  return (
    <div>
      <div class="background-notice">
        <div class="top">
          <div class="logo"></div>
          <a class="help" href="https://karrot.kr/">
            Karrot이 처음이신가요?
          </a>
        </div>
        <div class="label-title"></div>
        <div class="label-guide">받으실 이메일 주소를 입력해주세요.</div>
        <div class="input-email">
          <input
            type="text"
            class="input-first"
            placeholder="이메일"
            id="emailId"
          />
          <span class="atMark">@</span>
          <input
            type="text"
            class="input-second"
            placeholder="직접입력"
            id="emailDomain"
          />

          {/* dromdown menu */}
          <div class="dropDownBtn" onClick={() => openDrop()}>
            {visible ? (
              <div class="dropDownMenu">
                {domainList.map(item => (
                  <div
                    class="dromItem"
                    key={item.id}
                    onClick={() => selectDrop(item.id)}
                  >
                    {item.domain}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div class="check-group">
          <div class="check-group-item">
            <input type="checkbox" id="check1"></input>
            <label for="check1">
              <span></span>
            </label>
            <span onClick={() => check("check1")}>
              본인 이메일 주소가 맞습니다.
            </span>
          </div>
          <div class="check-group-item">
            <input type="checkbox" id="check2"></input>
            <label for="check2">
              <span></span>
            </label>
            <span onClick={() => check("check2")}>
              개인정보 수집, 이용에 동의합니다.
            </span>
          </div>
        </div>
        <div class="submit-btn" onClick={() => req()}></div>

        {emailState ? (
          <div class="indicator">
            <LoadingIndicator
              alpha={1}
              segmentWidth={10}
              segmentLength={20}
              spacing={25}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationTalk;
