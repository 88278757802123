import React, { Component } from "react";
// import logo from './logo.svg';
// import './App.css';
import { Route, Switch } from "react-router-dom";
import { Landing, NotificationTalk, Policy, Terms } from "pages";

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/Policy" component={Policy} />
          <Route path="/Terms" component={Terms} />
          <Route
            path="/NotificationTalk/req/:val"
            component={NotificationTalk}
          />
          <Route path="/NotificationTalk" component={NotificationTalk} />
        </Switch>
      </div>
    );
  }
}

export default App;
