import React from "react";
import "./Landing.css";

const Landing = () => {
  // const onScroll = event => {
  //   // console.log(window.scrollY);
  //   if (window.scrollY > 0) {
  //     document.getElementById("footer").classList.add("show");
  //     let storeBtns = document.getElementsByTagName("a");

  //     for (let i = 0; i < storeBtns.length; i++) {
  //       storeBtns[i].classList.remove("disabled");
  //     }
  //   } else {
  //     document.getElementById("footer").classList.remove("show");

  //     let storeBtns = document.getElementsByTagName("a");

  //     for (let i = 0; i < storeBtns.length; i++) {
  //       storeBtns[i].classList.add("disabled");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // }, []);

  return (
    <div>
      <div class="background"></div>
      <div class="background-bottom">
        <div class="background-bottom-content">
          <a href="/Policy">개인정보처리방침</a> |{" "}
          <a href="/Terms">회원이용약관</a>
        </div>
      </div>
      <div class="side">
        <a
          class="side-sendCert"
          target="_blank"
          rel="noopener noreferrer"
          href="http://112.175.236.72:8020/CMP/cmp/certrelay.jsp"
        ></a>
        {/* <div class="temp"></div> */}
      </div>
      <div class="side-right-bottom">
        <a
          class="link-icon kakao"
          target="_blank"
          rel="noopener noreferrer"
          href="http://pf.kakao.com/_BxixeeT"
        ></a>
        <a
          class="link-icon facebook"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/kedblog/"
        ></a>
        <a
          class="link-icon blog"
          target="_blank"
          rel="noopener noreferrer"
          href="https://blog.naver.com/kedebiz"
        ></a>
      </div>
      <div class="side-left-bottom">
        <iframe
          width="412"
          height="231"
          src="https://www.youtube.com/embed/5S4adxI9hTk"
          frameborder="0"
          allow="-accelerometer; autoplay; encrypted-media; gyroscope; pic-ture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div id="footer" class="footer-wrapper">
        <div class="footer">
          <div class="wrapper-store">
            {/* <a
              class="playstore"
              href="https://play.google.com/store/apps/details?id=com.ked.karrot"
            ></a>
            <a class="appstore" onClick={onClickAppStore}></a> */}
            <div
              class="playstore"
              alt="다운로드하기 Google Play"
              src="https://play.google.com/intl/ko/badges/static/images/badges/ko_badge_web_generic.png"
            >
              <a
                class="playstore-clickRange"
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.ked.karrot&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar25151"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
